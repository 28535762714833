@import "~@vaultinum/app-sdk/dist/style.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Montserrat";
        src: url("../public/font/Montserrat-VariableFont_wght.ttf");
    }
    html {
        font-family: "Montserrat", sans-serif;
        scroll-behavior: smooth;
    }

    h1 {
        @apply mb-5 font-bold text-label;
    }
}

@import "./design-system/styles/vars.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "inter";
        src: url("./design-system/styles/font/inter.ttf");
    }
    html {
        font-family: "inter", sans-serif;
        scroll-behavior: smooth;
    }
    h1 {
        @apply text-3xl !important;
    }
    h2 {
        @apply text-2xl !important;
    }
    h3 {
        @apply text-xl !important;
    }
    svg {
        @apply align-baseline !important;
    }

    blockquote,
    dd,
    dl,
    figure,
    hr,
    p,
    input,
    textarea,
    select,
    button,
    optgroup,
    pre,
    li {
        margin: 0 !important;
        font-size: 0.875rem !important;
    }

    fieldset,
    ol,
    ul {
        margin: 0 !important;
        padding: 0 !important;
    }

    a {
        text-decoration: inherit !important;
    }
    /* End override */
    /* Scroll bar override */
    ::-webkit-scrollbar {
        width: 0.4rem;
    }
    ::-webkit-scrollbar-track {
        @apply bg-white;
    }
    ::-webkit-scrollbar-thumb {
        @apply bg-gray-200;
    }
    ::-webkit-scrollbar-thumb:hover {
        @apply bg-gray-300;
    }
    * {
        scrollbar-width: thin;
        scrollbar-color: rgba(229, 231, 235) white;
    }
}
